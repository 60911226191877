.start-screen {
	padding: 10px;
	background-color: lightgray;
	max-width: 40vw;
	color: black;
}

.Quiz {
	width: 100vw;
	background: url("/public/big-brother-1984.webp") no-repeat center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 0 1 100vw;
	color: white;
}

@keyframes quiz-start-text {
	from {
		opacity: 0;
		scale: 1.1;
		rotate: 2deg;
	}

	40%, 60% {
		opacity: 1;
	}

	to {
		opacity: 0;
		scale: 0.9;
		rotate: -2deg;
	}
}

.quiz-start-text {
	animation-name: quiz-start-text;
	animation-duration: 6s;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

@keyframes appear2 {
	from, 75% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.party-maxims-text {
	position: absolute;
	top: 0;
	left: 0;
	animation-name: appear2;
	animation-timing-function: linear;
	animation-duration: 6s;
	color: red;
	font-weight: 900;
	font-size: 1.5rem;
	letter-spacing: 15px;
}

.party-maxims-text > label {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	text-align: center;
}

@keyframes appear {
	from {
		opacity: 0;
		scale: 0.7;
	}

	to {
		opacity: 1;
		scale: 1;
	}
}

@keyframes disappear {
	from {
		opacity: 1;
		scale: 1;
	}

	to {
		opacity: 0;
		scale: 0.7;
	}
}

.question-area {
	animation-name: appear;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(.85,0,.15,1);
}

.question-area.disappearing {
	animation-name: disappear;
}

.question-area > .answer-area {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	width: 50vw;
}

.question-area > .answer-area > button {
	background-color: #512c2c;
	color: white;
	border: 4px dashed white;
	border-radius: 20px;
	padding: 20px 10px;
	font-size: 1.5rem;
	transition: background-color 250ms;
}

.question-area > .answer-area > button:hover {
	background-color: #6e3636;
	cursor: pointer;
}

.errors {
	position: absolute;
	left: 5px;
	bottom: 5px;
	background-color: #222;
	padding: 10px 10px 0;
	border-radius: 10px;
	animation-name: appear2;
	animation-timing-function: linear;
	animation-duration: 6s;
}

.errors > img {
	margin: 5px;
	border-radius: 100vw;
}

@keyframes fail-screen-appear {
	from {
		opacity: 0;
		scale: 1.9;
	}

	to {
		opacity: 1;
		scale: 1;
	}
}

.fail-screen {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	font-size: 3rem;
	background-size: cover;
	background: url("/public/lose.webp") center;
	animation-name: fail-screen-appear;
	animation-duration: 500ms;
	animation-timing-function: cubic-bezier(1, 0, 1, 1);
}

.fail-screen > * {
	rotate: -2deg;
}

.fail-screen > div:first-child {
	width: 100vw;
	height: 100vh;
	background: linear-gradient(to top, #000a 30%, transparent 60%);
	position: absolute;
	left: 0;
	top: 0;
	rotate: 0deg;
}

.fail-screen > h1 {
	margin: 0 0 20px;
	font-family: monospace;
	rotate: 3deg;
}

.fail-screen > button {
	background-color: red;
	color: white;
	padding: 20px;
	font-size: 1.5rem;
	border: none;
	cursor: pointer;
	margin-bottom: 10px;
}

@keyframes reward-appear {
	from {
		background-color: transparent;
	}

	to {
		background-color: black;
	}
}

.reward {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	animation-name: reward-appear;
	animation-duration: 300ms;
	animation-timing-function: linear;
	background-color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	transition: background-color, color;
	transition-duration: 500ms;
}

.reward[data-light="true"] {
	background-size: cover;
	background: url("/public/win.webp") center;
}

@keyframes reward-text-appear {
	from {
		opacity: 0;
		letter-spacing: 0;
	}

	to {
		opacity: 1;
		letter-spacing: 3px;
	}
}

.reward > div:first-child {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #000a;
}

.reward > h1, .reward > h3, .reward > h5 {
	opacity: 0;
	margin: 10px 0;
	animation-name: reward-text-appear;
	animation-duration: 750ms;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	text-shadow: -1px 1px black, 1px -1px 1px black, -1px -1px 1px black, 1px 1px 1px black;
}

@keyframes reward-key-appear {
	from {
		bottom: -50px;
		opacity: 0;
	}

	to {
		bottom: -15px;
		opacity: 1;
	}
}

@keyframes rainbow-text {
	from {
		color: red;
	}

	10% {
		color: orange;
	}

	20% {
		color: yellow;
	}

	30% {
		color: greenyellow;
	}

	40% {
		color: limegreen;
	}

	50% {
		color: deepskyblue;
	}

	60% {
		color: blue;
	}

	70% {
		color: darkslateblue;
	}

	80% {
		color: purple;
	}

	90% {
		color: magenta;
	}

	to {
		color: red;
	}
}

.reward > div:last-child {
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
	background-color: #1B2838;
	box-shadow: 0 0 30px 15px #131d28;
	border: 1px solid #283d56;
	padding: 10px;
	opacity: 0;
	border-radius: 10px;
	animation-name: reward-key-appear;
	animation-duration: 500ms;
	animation-timing-function: cubic-bezier(0, 1, 1, 1);
	animation-fill-mode: forwards;
	animation-delay: 10250ms;
}

.reward > div:last-child > p {
	color: white;
	margin: 0;
	font-weight: 700;
	font-size: 1.25rem;
}

.reward > div:last-child > code {
	background-color: #2B475E;
	color: #55CAFD;
	border-radius: 5px;
	font-size: 1.5rem;
	margin-top: 5px;
	padding: 3px 10px;
}

.reward > div:last-child > label {
	animation-name: rainbow-text;
	animation-timing-function: linear;
	animation-duration: 12s;
	animation-iteration-count: infinite;
	font-weight: 900;
	letter-spacing: 1px;
}
